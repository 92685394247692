'use strict'

import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
let user = {}

interface UserInfo {
  username: string
}

interface LockedInfo {
  chatId: string
  user: UserInfo
}

interface UpdateInfo {
  chatId: number
}

const token = localStorage.getItem('token')

let events = new EventSource('/events?clientId=' + token)

function reconnect(): void {
  if (token) {
    setTimeout(() => {
      console.log(`Attempting to reconnect to EventSource in 5000ms...`)
      events = new EventSource('/events?clientId=' + token)
      getLocks()
    }, 5000)
  }
}

function isConnected(): boolean {
  return events?.readyState === EventSource.OPEN || events?.readyState === EventSource.CONNECTING
}

events.onopen = () => {
  console.log('EventSource connected.')
}

events.onmessage = (event) => {
  const parsedData = JSON.parse(event.data)
  if (parsedData.operation === 'lockchat') {
    const state = useStateStore()
    state.addLockedChat(parsedData)
  }
  if (parsedData.operation === 'unlockchat') {
    const state = useStateStore()
    state.removeLockedChat(parsedData)
  }
  if (parsedData.operation === 'chatUpdate') {
    const state = useStateStore()
    state.appendMessage(parsedData)
  }
}

events.onerror = (error: Event) => {
  console.error('EventSource error:', error)
  reconnect()
}

setInterval(function () {
  const connected = isConnected()
  if (!connected) {
    console.log('would try to recconnect')
    reconnect()
  }
}, 5000)

function getLocks() {
  if (!token) return false
  axios
    .post(
      '/chat/getLocks',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      const state = useStateStore()
      state.setLockedChats(response.data)
    })
}

getLocks()

const cleanup = () => {
  events.close()
}

window.addEventListener('beforeunload', cleanup)

export const useStateStore = defineStore('state', {
  state: () => ({ lockedChats: <LockedInfo[]>[], logs: <UpdateInfo[]>[] }),
  actions: {
    appendMessage(message: UpdateInfo) {
      this.logs.push(message)
    },
    setLockedChats(locks: LockedInfo[]) {
      this.lockedChats = locks
    },
    addLockedChat(lockedInfo: LockedInfo) {
      const alreadyLocked = this.lockedChats.find(
        (singleLock) => singleLock.chatId === lockedInfo.chatId
      )

      if (!alreadyLocked) {
        this.lockedChats.push(lockedInfo)
      }
    },
    removeLockedChat(lockedInfo: LockedInfo) {
      this.lockedChats = this.lockedChats.filter(
        (singleLock) => singleLock.user.username !== lockedInfo.user.username
      )
    }
  }
})
