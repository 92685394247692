<script setup lang="ts">
import { reactive, onMounted, ref } from 'vue'
import axios from 'axios'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useAuthStore } from '@/stores/auth'
import { useStateStore } from '@/stores/state'
import LearningStatsLine from '@/components/LearningStatsLine.vue'
import BeltSelect from '@/components/BeltSelect.vue'
import { FilterMatchMode } from '@primevue/core/api'
import InputText from 'primevue/inputtext'

const store = useAuthStore()
const state = useStateStore()

const chats = reactive({
  list: []
})

state.$subscribe((mutation, state) => {
  // console.log(state.lockedChats)
})

onMounted(() => {
  axios
    .post(
      '/users/all',
      {},
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      chats.list = response.data
    })
  unlockChats()
})

async function unlockChats() {
  axios.post(
    `/chat/unlock`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    }
  )
}

function isChatLocked(chatId: string) {
  return state.lockedChats.find((singleLock) => parseInt(singleLock.chatId) === parseInt(chatId))
}

const filters = ref()

const initFilters = () => {
  filters.value = {
    shop_name: { value: null, matchMode: FilterMatchMode.CONTAINS }
  }
}

initFilters()
</script>

<template>
  <main>
    <DataTable
      sortField="user_id"
      :sortOrder="-1"
      :value="chats.list"
      tableStyle="min-width: 50rem"
      v-model:filters="filters"
      :globalFilterFields="['shop_name']"
      filterDisplay="menu"
    >
      <Column sortable field="user_id" header="user_id">
        <template #body="slotProps">
          <RouterLink :to="'/chat/' + slotProps.data.user_id">{{
            slotProps.data.user_id
          }}</RouterLink>
        </template>
      </Column>
      <Column sortable field="username" header="Логин"></Column>
      <Column sortable field="shop_name" header="Компания">
        <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" placeholder="Search by name" />
        </template>
      </Column>
      <Column sortable field="learn_type" header="Тип обучения">
        <template #filter="{ filterModel }">
          <InputText v-model="filterModel.value" type="text" placeholder="Search by type" />
        </template>
      </Column>

      <Column header="Сейчас занят">
        <template #body="slotProps">
          {{ isChatLocked(slotProps.data.user_id)?.user.username }}
        </template>
      </Column>
      <Column header="Прогресс">
        <template #body="slotProps">
          <LearningStatsLine
            :key="slotProps.data.user_id"
            :userId="parseInt(slotProps.data.user_id)"
          />
        </template>
      </Column>

      <Column sortable field="name" header="Имя"></Column>
      <Column sortable field="history" header="История"></Column>
      <!-- <Column field="tilda_user_id" header="tilda_user_id"></Column> -->
      <Column sortable field="tilda_user_name" header="tilda_user_name"></Column>
      <Column field="badge_user_id" header="badge_user_id"></Column>
      <Column field="belt" header="Пояс">
        <template #body="slotProps">
          <BeltSelect :data="slotProps.data" />
        </template>
      </Column>
    </DataTable>
  </main>
</template>
