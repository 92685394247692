<script setup lang="ts">
import { reactive, onMounted, ref } from 'vue'
import axios from 'axios'
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import { useAuthStore } from '@/stores/auth'
import { type TaskResult } from '@/types'
import RadioButton from 'primevue/radiobutton'
import { useToast } from 'primevue/usetoast'
import Fieldset from 'primevue/fieldset'

function prepeareDate(date: Date): string {
  console.log(date)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getFullYear()

  return `${day}-${month}-${year}`
}

const toast = useToast()
const store = useAuthStore()

const loading = ref(false)
const visible = ref(false)
const templateText = ref('')
const templates = reactive({
  list: [] as TaskResult[]
})

const currentTaskResult = reactive({} as TaskResult)

function loadTasks() {
  loading.value = true
  axios
    .get('/taskResults', {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      loading.value = false
      templates.list = response.data
    })
}

onMounted(() => {
  loadTasks()
})

function editTaskResult(taskResult: TaskResult) {
  currentTaskResult.taskid = taskResult.taskid
  Object.keys(taskResult).forEach((singleKey: string) => {
    currentTaskResult[singleKey] = taskResult[singleKey]
  })
  currentTaskResult.confirmation = taskResult.has_completed

  visible.value = true
}

function toggleLearn(userId: string, lessonId: string, toggle: boolean) {
  axios
    .post(
      '/excerciseToggle/',
      { userId: userId, lessonId: lessonId, toggle, fromAdmin: true },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      if (response.status === 200) {
        toast.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Статус урока обновлен',
          life: 3000
        })
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Ошибка обновления',
          life: 3000
        })
      }
    })
}

function updateSkill(userId: string, skillLevel: string) {
  return axios
    .post(
      '/updateUserSkillStatus',
      { userId: userId, lessonId: skillLevel, skillLevel },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      if (response.status === 200) {
        toast.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Уровень сотрудника обновлен',
          life: 3000
        })
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Ошибка обновления уровня сотрудника',
          life: 3000
        })
      }
    })
}

async function updateResult() {
  const newTask = { ...currentTaskResult }

  toggleLearn(newTask.user_id, newTask.lesson_id, Boolean(newTask.confirmation))

  if (newTask.lesson_id === '0') {
    await updateSkill(newTask.user_id, Boolean(newTask.confirmation) ? 'Опытный' : 'Новичок')
  }

  axios
    .put('/taskResults', newTask, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then(() => {
      visible.value = false
      loadTasks()
    })
}

function determineStatus(taskResult: TaskResult) {
  if (!taskResult.recommendation) {
    return 'не проверено'
  }
  return taskResult.has_completed ? 'зачет' : 'не зачет'
}
</script>

<template>
  <main>
    <Dialog
      v-model:visible="visible"
      modal
      :header="currentTaskResult.lesson_name"
      :style="{ width: '50rem' }"
    >
      <div class="max-h-[50vh] overflow-y-auto">
        <Fieldset legend="Ответ:">
          <Textarea
            v-model="currentTaskResult.result"
            rows="15"
            cols="60"
            width="100%"
            variant="filled"
            :disabled="true"
          />
        </Fieldset>
        <br />
        <Fieldset legend="Рекомендация:">
          <p class="m-0">
            <Textarea
              v-model="currentTaskResult.recommendation"
              rows="5"
              cols="60"
              :width="100"
              variant="filled"
              :style="{ width: '100%' }"
            />
          </p>
        </Fieldset>
      </div>
      <div class="flex flex-col min-h-[20vh] justify-center">
        <div class="flex flex-wrap gap-4 py-0.5">
          <div class="flex items-center py-3">
            <RadioButton
              v-model="currentTaskResult.confirmation"
              inputId="ingredient1"
              name="pizza"
              :value="true"
            />
            <label for="ingredient1" class="ml-2">Зачет</label>
          </div>
          <div class="flex items-center">
            <RadioButton
              v-model="currentTaskResult.confirmation"
              inputId="ingredient2"
              name="pizza"
              :value="false"
            />
            <label for="ingredient2" class="ml-2">Незачет</label>
          </div>
        </div>
        <div class="flex py-3">
          <Button
            class="w-full"
            type="button"
            label="Завершить оценку"
            @click="updateResult"
          ></Button>
        </div>
        <div class="flex py-3">
          <Button
            class="w-full"
            type="button"
            label="Отмена"
            severity="secondary"
            @click="visible = false"
          ></Button>
        </div>
      </div>
    </Dialog>

    <DataTable :value="templates.list" tableStyle="min-width: 50rem">
      <Column sortable field="company" header="Клиент"></Column>
      <Column sortable field="shop" header="Магазин"></Column>
      <Column sortable field="username" header="Сотрудник"></Column>
      <Column sortable field="email" header="Почта"></Column>
      <Column sortable field="learntype" header="Тип Обучения"></Column>
      <Column sortable field="category" header="Уровень"></Column>
      <Column sortable field="created_at" header="Дата">
        <template #body="slotProps">
          {{ prepeareDate(new Date(slotProps.data.created_at)) }}
        </template>
      </Column>
      <Column sortable field="lesson_name" header="Задание"></Column>
      <Column header="Подробнее">
        <template #body="slotProps">
          <Button
            label="редактировать"
            @click="editTaskResult(slotProps.data)"
            severity="secondary"
          /> </template
      ></Column>
      <Column sortable field="has_completed" header="Статус">
        <template #body="slotProps">
          {{ determineStatus(slotProps.data) }}
        </template>
      </Column>
    </DataTable>
  </main>
</template>
