<script setup lang="ts">
import { reactive, onMounted, ref } from 'vue'
import axios from 'axios'
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Editor from 'primevue/editor'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import { useAuthStore } from '@/stores/auth'
import { type Recommendation } from '@/types'
const store = useAuthStore()

const loading = ref(false)
const visible = ref(false)
const templateText = ref('')
const templates = reactive({
  list: []
})
const currentTemplate = reactive({
  author: '',
  id: 0,
  templateText: '',
  editable: true,
  comments: []
})

function loadTemplates() {
  loading.value = true
  axios
    .get('/templates', {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      loading.value = false
      templates.list = response.data
      currentTemplate.id = templates.list.length + 1
    })
}

onMounted(() => {
  loadTemplates()
})

function createDialog(recomm: Recommendation) {
  // currentTemplate.id = recomm.id
  currentTemplate.author = store.user.username
  currentTemplate.templateText = recomm.template_text
  visible.value = true
  // loadComments()
}

function editDialog(recomm: Recommendation) {
  currentTemplate.id = recomm.id
  currentTemplate.author = recomm.author
  currentTemplate.editable = recomm.author === store.user.username
  currentTemplate.templateText = recomm.template_text

  visible.value = true
  // loadComments()
}

function upsertTemplate() {
  axios
    .post(
      '/templates',
      {
        id: currentTemplate.id,
        author: currentTemplate.author,
        templateText: currentTemplate.templateText
      },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then(() => {
      visible.value = false
      loadTemplates()
    })
}
</script>

<template>
  <main>
    <Dialog v-model:visible="visible" modal header="Шаблон" :style="{ width: '85rem' }">
      <div class="max-h-[40vh] overflow-y-auto">
        <Textarea
          v-model="currentTemplate.templateText"
          rows="5"
          cols="60"
          :width="100"
          variant="filled"
          :disabled="!currentTemplate.editable"
        />
      </div>

      <!-- <DataTable :value="currentCommentList.comments" paginator :rows="8">
        <Column field="created_at" sortable header="created_at" style="width: 15%"></Column>
        <Column field="author" header="author" style="width: 10%"></Column>
        <Column field="text" header="text"></Column>
      </DataTable>
      <Textarea v-model="commentValue" rows="5" cols="60" :width="100" variant="filled" /> -->

      <div class="flex justify-end gap-2">
        <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
        <Button type="button" label="Save" @click="upsertTemplate"></Button>
      </div>
    </Dialog>

    <DataTable :value="templates.list" tableStyle="min-width: 50rem">
      <Column field="id" header="id"></Column>
      <Column field="author" header="author"></Column>
      <Column field="template_text" header="template_text"></Column>
      <Column>
        <template #body="slotProps">
          <Button label="редактировать" @click="editDialog(slotProps.data)" severity="secondary" />
        </template>
      </Column>
    </DataTable>
    <Button type="button" label="Новый шаблон" :loading="loading" @click="createDialog" />
  </main>
</template>
