<script setup lang="ts">
import { useRouter } from 'vue-router'
import { reactive } from 'vue'
import axios from 'axios'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import { useAuthStore } from '@/stores/auth'
const store = useAuthStore()
const router = useRouter()
const data = reactive({
  username: '',
  password: ''
})

function login() {
  axios.post('/login', { username: data.username, password: data.password }).then((response) => {
    store.setToken(response.data.accessToken)
    router.push('/list')
  })
}

// function autologin() {
//   axios.post('/login', { username: 'test', password: 'test' }).then((response) => {
//     store.setToken(response.data.accessToken)
//     router.push('/list')
//   })
// }
</script>

<template>
  <main>
    <form @submit="login">
      <div class="mb-3">
        <label for="username" class="font-semibold gap-3">Username</label>
        <InputText
          v-model="data.username"
          id="username"
          class="flex-auto"
          autocomplete="current-username"
        />
      </div>
      <div class="mb-5">
        <label for="password" class="font-semibold gap-3">Password</label>
        <Password autocomplete="current-password" v-model="data.password" :feedback="false" />
      </div>
      <div>
        <Button class="m-3" type="button" label="Save" @click="login"></Button>
        <Button class="m-3" type="button" label="Cancel" severity="secondary"></Button>
        <!-- <Button class="m-3" type="button" label="login" @click="autologin" severity="secondary"></Button> -->
      </div>
    </form>
  </main>
</template>

<style scoped>
main {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
