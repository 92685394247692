'use strict'

import { defineStore } from 'pinia'
import { jwtDecode } from 'jwt-decode'

let user = {}

interface UserInfo {
  username: string
  admin: boolean
  id: string
}

const token = localStorage.getItem('token')
if (token) {
  user = jwtDecode(token)
}

export const useAuthStore = defineStore('auth', {
  state: () => ({ token: token as string, user: user as UserInfo }),
  actions: {
    setToken(token: string) {
      this.token = token
      localStorage.setItem('token', token)
    }
  }
})
